<template>
	<main class="enmk">
		<vue-title title="English-Macedonian Converter Online"></vue-title>
		<div class="container">
			<div class="row mt-3">
				<div class="col-lg-8 pb-4">
					<h1><abbr title="English">EN</abbr>-<abbr title="Macedonian">MK</abbr> Converter<small> Online</small></h1>
					<div class="alert alert-info my-5">EN-MK Converter is a web application that converts text between English Latin and Macedonian Cyrillic alphabet. Select the conversion method, then enter or paste your text in the field "Input", and then click the button [Convert].</div>
					<div id="enmk">
						<div class="mb-2">Select the conversion method:</div>
						<div v-for="method in arrMethods" :key="method.value" class="custom-control custom-radio">
							<input type="radio" class="custom-control-input" v-model="selectedMethod" :id="method.value" :value="method.value">
							<label class="custom-control-label mb-3" :for="method.value">
								<div v-html="method.title"></div>
								<span v-html="method.exampleFrom" class="badge badge-secondary text-monospace mr-2 py-1 px-2"></span> &#8674;
								<span v-html="method.exampleTo" class="badge badge-secondary text-monospace ml-2 py-1 px-2"></span>
							</label>
							<div v-if="method.value === selectedMethod" class="card card-body small mb-4" v-html="method.description"></div>
						</div>
						<hr class="my-4">
						<div class="form-group">
							<label for="convert-case">Convert case:</label>
							<select v-model="selectedCase" id="convert-case" class="form-control">
								<option v-for="option in arrCases" :key="option.value" :value="option.value">
									{{ option.text }}
								</option>
							</select>
						</div>
						<hr class="my-4">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<button class="float-right btn btn-link p-0" type="button" title="Clear"
										@click="clear">
											<svg-icon icon="delete"></svg-icon>
									</button>
									<label for="text-input">Input:</label>
									<textarea v-model="input" id="text-input" cols="30" rows="10" class="form-control"></textarea>
									<p class="small text-info">The text to be converted goes here.</p>
								</div>
							</div>
							<div class="col-md-6">
								<div class="form-group">
									<button class="float-right btn btn-link p-0" type="button" title="Copy" v-clipboard:copy="output">
											<svg-icon icon="copy"></svg-icon>
									</button>
									<label for="text-output">Output:</label>
									<textarea v-model="output" id="text-output" cols="30" rows="10" class="form-control"></textarea>
									<p class="small text-info">The converted text goes here.</p>
								</div>
							</div>
						</div>
						<p class="my-4"><button class="btn btn-primary" @click="convert">Convert</button></p>
					</div>
				</div>
				<div class="col-lg-4 pb-4">
					<div id="sidebar-tabs" class="mt-4">
						<ul class="nav nav-tabs">
							<li class="nav-item">
								<a class="nav-link active" id="navlink1" href="#" @click.prevent="change(1)">Input Languages</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" id="navlink2" href="#" @click.prevent="change(2)">Methods</a>
							</li>
						</ul>
						<div class="tab-content mt-4 small">
							<div class="tab-pane show active" id="tabpane1">
								<p>Installing additional input languages in Microsoft Windows 10:</p>
								<ol>
									<li>Select the Start button, then Settings > Time &amp; language > Region &amp; language.</li>
									<li>Select Add a language.</li>
									<li>Choose the language you want to use from the list, and then select Next.</li>
									<li>Review any language features you want to set or install, and select Install.</li>
									<li>Note: To switch the input language you're using in Windows 10, select the language abbreviation on the far right of the taskbar, and then select the language or input method you want to use. You can also press and hold the Windows logo key and then repeatedly press the Spacebar to cycle through all your languages.</li>
								</ol>
							</div>
							<div class="tab-pane" id="tabpane2">
								<p>Although similar, the conversion methods in this website are a little different from the method of <a target="_blank" href="https://en.wikipedia.org/wiki/Romanization_of_Macedonian" rel="noopener noreferrer">romanization of the Macedonian language</a>, which is <a target="_blank" href="http://geonames.nga.mil/gns/html/Romanization/Romanization_Macedonian.pdf" rel="noopener noreferrer">standardized</a> in the linguistic science.</p>
								<p>For more information, read these Wikipedia articles about <a target="_blank" href="https://en.wikipedia.org/wiki/Character_encoding" rel="noopener noreferrer">character encoding</a>, <a target="_blank" href="https://en.wikipedia.org/wiki/Transliteration" rel="noopener noreferrer">transliteration</a> and <a target="_blank" href="https://en.wikipedia.org/wiki/Transcription_(linguistics)" rel="noopener noreferrer">transcription</a>.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios';
import changeTab from '../_js/changeTab.js';

export default {
	name: "enmk",
	data: function() {
		return {
			selectedMethod: 1,
			arrMethods: [
				{
					value: 1,
					title: "Transliterate from MK Cyrillic to EN Latin",
					exampleFrom: "о п ш ѓ л ч ќ",
					exampleTo: "o p sh gj l ch kj",
					description:
						'Transliterates Macedonian Cyrillic text written in UTF-8 or Windows-1251 character encoding into English Latin alphabet. Certain Cyrillic letters are represented with two or three Latin letters, like for example: "ш" = "sh", or "џ" = "dzh".'
				},
				{
					value: 2,
					title: "Transliterate from EN Latin to MK Cyrillic",
					exampleFrom: "o p sh gj l ch kj",
					exampleTo: "о п ш ѓ л ч ќ",
					description:
						'Transliterates English Latin text into Macedonian Cyrillic alphabet (UTF-8 encoded), treating some groups of two or three Latin letters as a single Cyrillic letter. For example: "kj" = "ќ", or "zh" = "ж". Note that the following English Latin letters will remain the same: "q", "w", "x", and "y", because they are not used in the opposite transliteration, from Cyrillic to Latin.'
				},
				{
					value: 3,
					title: "Convert from MK Cyrillic to MK with Latin font",
					exampleFrom: "о п ш ѓ л ч ќ",
					exampleTo: "o p { | l ~ }",
					description:
						'This method converts text written in Macedonian Cyrillic character encoding into Latin-encoded text which contains some special symbols instead of letters. For example: "ч" = "~", or "ш" = "{". Writing Macedonian text using a font for styling has become deprecated after operating systems introduced multi-language support (around 1998).'
				},
				{
					value: 4,
					title: "Convert from MK with Latin font to MK Cyrillic",
					exampleFrom: "o p { | l ~ }",
					exampleTo: "о п ш ѓ л ч ќ",
					description:
						'This method converts text written using Macedonian font for styling &ndash; (i.e. the bad old-school way) &ndash; into UTF-8 encoded Macedonian Cyrillic alphabet. For example: "|" = "ѓ", or "w" = "њ". Use this option for converting old documents which use fonts like "MAC C Times" or "MAC C Swiss" into modern-day unicode.'
				}
			],
			selectedCase: 0,
			arrCases: [
				{ value: 0, text: "Unchanged (default)" },
				{ value: 1, text: "Lowercase" },
				{ value: 2, text: "Uppercase" }
			],
			input: "",
			output: ""
		};
	},
	methods: {
		convert: function() {
			var vm = this;
			axios
				.post("enmk-convert.php", {
					conv_method: vm.selectedMethod,
					conv_case: vm.selectedCase,
					txt_input: vm.input
				})
				.then(function(response) {
					if (response.status === 200) {
						vm.output = response.data;
					} else {
						vm.output = "Error fetching the strings.";
					}
				})
				.catch(function() {
					vm.output = "Error fetching the strings.";
				});
		},
		clear: function() {
			this.input = '';
		},
		change: function(id) {
			changeTab.show(id);
		}
	}
};
</script>
